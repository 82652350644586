<template>
    <b-row>
        <b-col md="12">
            <div class="text-right">
                <b-button class="btn btn-sm btn-success mb-2" @click="printDiv">
                    <i class="fa fa-print"></i>{{ $t('globalTrans.print') }}
                </b-button>
            </div>
            <div class="table-responsive" id="printArea">
                <table class="table table-sm table-bordered main-table-it" style="width:100%">
                    <tr>
                        <th style="vertical-align: middle;width: 5%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                        <th style="vertical-align: middle" class="text-center" rowspan="2">{{ $t('boro_import.company_details') }}</th>
                        <th style="vertical-align: middle" class="text-center" colspan="9" > {{ local === 'bn' ? setFiscalYear() : '' }} {{ $t('boro_import.import_seed_amount') }} {{ local === 'en' ? setFiscalYear() : '' }}</th>
                    </tr>
                    <tr>
                        <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.variety_name') }}</th>
                        <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.meeting_date') }}</th>
                        <th style="vertical-align: middle" class="text-center"> {{ $t('boro_import.meeting_number') }}</th>
                        <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.af_1') }}</th>
                        <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.a_line') }}</th>
                        <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.r_line') }}</th>
                        <th style="vertical-align: middle; width: 10%"> {{ $t('boro_import.seed_produce') }}</th>
                        <th style="vertical-align: middle" class="text-center" >{{ $t('boro_import.comment') }}</th>
                        <th style="vertical-align: middle" class="text-center">{{ $t('boro_import.abadi_jomi') }}</th>
                    </tr>
                    <slot v-for="(item, index) in applicantData">
                        <tr v-for="(itm, indx) in item.add_more_1575" :key="indx + 10">
                            <slot v-if="indx===0">
                        <!-- <pre>{{ item.add_more_1575 }}</pre> -->
                                <td class="text-center" :rowspan="item.add_more_1575.length">{{ $n(index+1) }}</td>
                                <td :rowspan="item.add_more_1575.length">
                                    {{ local === 'bn' ? item.name_and_a____7450 : item.name_and_a____8831 }}, {{ local === 'bn' ? item.addressbn_3295 : item.addressen_5439 }}
                                </td>
                            </slot>
                            <td class="text-center" v-for="(dataItem, key) in itm" :key="key+200">
                                <slot v-if="parseInt(dataItem) === 0 || !dataItem">
                                    -
                                </slot>
                                <slot v-else>
                                    {{ isNaN(dataItem) === false ? $n(dataItem) : dataItem }}
                                </slot>
                            </td>
                        </tr>
                    </slot>
                    <tr>
                        <td colspan="3" class="text-center">{{ $t('globalTrans.total') }}</td>
                        <td ></td>
                        <td ></td>
                        <td class="text-center">{{ getFirstItemAmount(applicantData) }}</td>
                        <td class="text-center">{{ getSecondItemAmount(applicantData) }}</td>
                        <td class="text-center">{{ getThirdItemAmount(applicantData) }}</td>
                        <td class="text-center">{{ getFourthItemAmount(applicantData) }}</td>
                        <td class="text-right" colspan="3"></td>
                    </tr>
                </table>
            </div>
        </b-col>
    </b-row>
</template>
<style lang="less" scoped>
    .main-table-it th, td {
        padding: 2px !important;
        font-size: 13px;
    }
</style>
<script>
import licenseRegistrationServiceBaseUrl from '@/config/api_config'
import reportPdf from './pdf/pdf_b'
import common from '@/mixins/common'
import { getNotice, getFiscalYear, formateArray } from '../fiscalYear'
export default {
    mixins: [common],
    props: ['applicationData'],
    data () {
        return {
            applicantData: []
        }
    },
    created () {
        this.applicationData.forEach(item => {
            if (item.add_more_1575.length > 0) {
                this.applicantData.push(item)
            }
        })
    },
    computed: {
        local: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        printDiv () {
            const reportTitle = this.$t('externalUserIrrigation.money_receipt_title')
            reportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, this.$route.params.id, reportTitle, this.columns, this.applicantData, this)
        },
        setFiscalYear () {
           const fiscalId = getNotice(this).fiscal_year_id
           return getFiscalYear(fiscalId, 'current')
        },
        getFirstItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 3) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getSecondItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 4) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getFourthItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 6) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getThirdItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_1575.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 5) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getStartData (item) {
            const array = []
            Object.values(item).forEach((itm, index) => {
                if (index === 0 || index === 1 || index === 2) {
                    const val = isNaN(itm) === false ? this.$n(itm) : itm
                    array.push(val)
                }
            })
            return array
        },
        getMiddleData (item) {
            return Object.values(item).filter((itm, index) => index !== 0 && index !== 1 && index !== 2 && index !== 7)
        },
        getEndData (item) {
            const data = item.map(item => {
                return item.Upazilla
            })
            return formateArray(data)
        }
    }
  }
</script>
