<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('li_step.application_search') }}</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(searchData)" >
                    <b-row>
                        <b-col lg="6" sm="6" md="6">
                            <ValidationProvider name="Application Id" vid="application_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('li_step.application_id') }}
                                </template>
                                <b-form-input
                                plain
                                v-model="search.application_id"
                                id="application_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col class="col-sm-2">
                            <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
                </ValidationObserver>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.application_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <router-link :to="{ name: getBtn(3).url, params: { stepChange }}" :title="getBtn(3).text" :class="`btn btn-sm btn-primary`" size="sm"><i :class="getBtn(3).icon" @click="SetMultiApplicantStep(1)"></i>{{ getBtn(3).text }}</router-link>
                    </template>
                    <template v-slot:body>
                        <div v-if="componentLoad">
                            <h4 class="text-center">Loading...</h4>
                        </div>
                        <div v-else>
                            <b-overlay :show="loadingState">
                                    <b-card no-body>
                                        <b-tabs card>
                                            <b-tab :title="$t('li_step.table_a')" active>
                                            <TableA :applicationData="applicationData" :key="updateComp" />
                                            </b-tab>
                                            <b-tab :title="$t('li_step.table_b')">
                                                <TableB :applicationData="applicationData" :key="updateComp" />
                                            </b-tab>
                                            <b-tab :title="$t('li_step.table_c')">
                                            <TableC :applicationData="applicationData" :key="updateComp" />
                                            </b-tab>
                                        </b-tabs>
                                        <div class="text-right">
                                            <router-link :to="{ name: getBtn(3).url, params: { stepChange }}" :title="getBtn(3).text" :class="`btn btn-sm btn-primary`" size="sm"><span @click="SetMultiApplicantStep(1)"><i :class="getBtn(3).icon"></i> {{ getBtn(3).text }}</span></router-link>
                                        </div>
                                    </b-card>
                            </b-overlay>
                        </div>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { boroRecommeendationList } from '../../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'
import TableA from './table-a'
import TableB from './table-b'
import TableC from './table-c'
import common from '@/mixins/common'
export default {
    mixins: [ModalBaseMasterList, common],
    components: {
        TableA,
        TableB,
        TableC
    },
    data () {
        return {
            stepChange: {
                application_id: [],
                step_id: 0,
                service_id: 0,
                org_id: 0,
                status: 1
            },
            search: {
                application_id: ''
            },
            menuId: '',
            stepId: '',
            formStepId: '',
            formId: '',
            appDetails: '',
            noticeId: '',
            applicationData: [],
            applicationIds: [],
            updateComp: 1,
            componentLoad: false
        }
    },
    created () {
      this.menuId = this.$route.params.id
      this.stepId = this.step.value
      this.getNoticeId(this.step.service_id)
      this.getServiceEntryForm(this.step.service_id)
      this.loadData()
    },
    computed: {
        organizationList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        menuInfoList () {
            const menuId = this.$route.params.id
            return this.$store.state.licenseRegistration.commonObj.menuInfoList.find(item => item.menu_id === parseInt(menuId))
        },
        step () {
            const stepId = this.menuInfoList.step_id
            return this.$store.state.licenseRegistration.commonObj.stepNamesList.find(item => item.value === parseInt(stepId))
        },
        laodList () {
            return this.$store.state.licenseRegistration.commonObj.laodList
        },
        local: function () {
            return this.$i18n.locale
        }
    },
    mounted () {
        core.index()
    },
    watch: {
        laodList: function (n, o) {
            if (n !== o) {
                this.loadData()
            }
        }
    },
    methods: {
        SetMultiApplicantStep (status) {
            this.applicationIds = []
            this.applicationData.forEach(item => {
                const applicationId = item.application_id
                this.applicationIds.push(applicationId)
            })
            this.stepChange.application_id = this.applicationIds
            this.stepChange.step_id = this.step.value
            this.stepChange.service_id = this.step.service_id
            this.stepChange.org_id = this.applicationData[0].org_id
            this.stepChange.status = status
        },
        getBtn (value) {
            return this.$store.state.licenseRegistration.commonObj.dynamicButtonList.find(item => item.value === parseInt(value))
        },
        searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage,
                service_id: this.step.service_id,
                formId: this.formId,
                noticeId: this.noticeId,
                formStepId: this.formStepId,
                step_id: this.step.value,
                is_org_admin: this.$store.state.Auth.authUser.is_org_admin
            })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            this.componentLoad = true
            RestApi.getData(licenseRegistrationServiceBaseUrl, boroRecommeendationList, params).then(response => {
                this.componentLoad = false
                if (response.success) {
                    const tmp = response.data.map(item => {
                       return Object.assign(item, { add_more_1575: JSON.parse(item.add_more_1575), add_more: JSON.parse(item.add_more), add_more_2087: JSON.parse(item.add_more_2087) })
                    })
                    this.applicationData = tmp
                    this.$store.dispatch('setList', response.data)
                    this.updateComp = this.updateComp + 1
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getServiceEntryForm (serviceId) {
            const formData = this.$store.state.licenseRegistration.commonObj.serviceStepForm.find(item => item.service_id === serviceId && item.form_type === 2)
            this.formId = formData.form_id
            this.formStepId = formData.step_id
        },
        getNoticeId (serviceId) {
            const formData = this.$store.state.licenseRegistration.commonObj.noticeList.findLast(item => item.service_id === serviceId && item.status === 1)
            this.noticeId = formData.value
        }
    }
  }
</script>
